<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="card">
        <div class="d-lg-flex justify-content-between align-items-center px-2 py-1">
          <h3 class="font-weight-bolder">
            {{ $t('unlockWithdrawal') }}
          </h3>

          <div class="d-flex">
            <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal">
                  <input
                    v-model="filter"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                  >
                  <div class="anan-input__suffix">
                    <i class="anan-input__clear-btn anan-icon" />
                    <i class="anan-input__suffix-icon anan-icon">
                      <i class="fal fa-search" />
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="px-2 align-items-center col-sm-3">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="User"
              rules="required"
            >
              <v-select
                v-model="cus_code"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :state="errors.length > 0 ? false:null"
                :options="customer"
                class="w-100 mr-1"
              >

                <template #option="{username}">
                  <span>{{ username }}</span>
                </template>
                <template #selected-option="{username}">
                  <span>{{ username }}</span>
                </template>
              </v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <button
            type="button"
            class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
            @click="submitAddNew()"
          >
            <span>+ {{ $t('addNewItem') }} </span>
          </button>
        </div>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="pl-2 pr-2 pt-1">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
            >
              <template #cell(number)="data">
                <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                  GUSR-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
                </b-link>
              </template>
              <!-- <template #cell(index)="data">
                      {{ perPage * (currentPage - 1) + (data.index + 1) }}
                    </template> -->
              <template #cell(action)="data">
                <feather-icon
                  icon="TrashIcon"
                  class="text-danger cursor-pointer"
                  @click="DeleteData(data.item)"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {
  BTable, BLink, BFormGroup, BPagination, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    BLink,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BPagination,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      isActive: 'all',
      required,
      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',
      totalRow: 0,
      filter: null,

      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'รถ' },
        { value: 2, text: 'เรือ' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      items: [],
      customer: [],
      is_delete: 0,
      cus_code: null,
      ID: this.$route.params.id,
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'cus_code', label: this.$t('customerCode'), thStyle: { width: '25%' }, thClass: 'text-center',
        },
        {
          key: 'fullname', label: this.$t('key-77'), sortable: false, thStyle: { width: '35%' }, thClass: 'text-center',
        },
        {
          key: 'email', label: this.$t('key-80'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'phone', label: this.$t('key-81'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData('all')
    this.getSelect()
  },
  methods: {
    async getSelect() {
      try {
        const { data: res } = await this.$http.get('/users/customer/active')
        this.customer = res
      } catch (e) {
        console.log(e)
      }
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('modal-add-new')
        }
      })
      const obj = {
        cus_code: this.cus_code.username,
        fullname: this.cus_code.fullname,
        email: this.cus_code.email,
        phone: this.cus_code.tel,
        is_delete: this.is_delete,
        uid: this.cus_code.id,
      }
      this.$http
        .post('/unlock/unlock_update', obj)
        .then(() => {
          this.getData(this.isActive)
          this.$bvToast.toast('บันทึกข้อมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },

    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    async getData(type) {
      this.showOver = true
      this.isActive = type
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/unlock/unlock_showall', { params })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
              cus_code: data.cus_code,
              fullname: data.fullname,
              email: data.email,
              phone: data.phone,
              is_delete: data.is_delete,
            }
            this.$http.post('/unlock/unlock_update', obj).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
